import React, { createElement, useState } from 'react';
import { Timeline, Comment, Avatar, Tooltip, Button } from 'antd';
import { LikeFilled } from '@ant-design/icons';
import Layout from '../components/Layout';
import { updateHistory } from '../constants';
import AboutUsBanner from '../components/AboutUsBanner';
import Container from '../components/Container';

export default () => {
  const [currentInfo, setCurrentInfo] = useState('');
  const [dataSource, setDataSource] = useState(updateHistory);


  // 从原后端HTML中获取更新历史；解析结果就是现在..constants/updateHistory的数据
  // useEffect(() => {
  //   fetch('/updateInfo')
  //     .then((res) => res.text())
  //     .then((htmlText) => {
  //       const body = htmlText.match(/<body[\S\s]*<\/body>/g);
  //       const segment = document.createElement('segment');
  //       segment.innerHTML = body;

  //       // 更新节点
  //       const updateNodes = segment.querySelectorAll('.stamp');

  //       const data = Array.from(updateNodes).map(n => {
  //         const title = n.querySelector('.title')?.textContent;
  //         const briefs = n.querySelector('.desc')?.textContent;
  //         const publishDate = n.querySelector('.time')?.textContent;
  //         const id = n['id']?.split('updateInfo_')?.pop();
  //         const details = [];

  //         return { title, briefs, publishDate, id, details };
  //       });
  //       setDataSource(data);
  //     });
  // }, []);

  // TODO 点赞要调接口
  const like = (updateItem, detail) => {
    const targetParent = dataSource.find((item) => item.id === updateItem.id) || {};
    const targetDetail = (targetParent.details || []).find((item) => item.id === detail.id) || {};
    const fData = new FormData();
    fData.append('detailid', targetDetail.id);
    fetch(`/_api/thumbup`, {
      method: 'POST',
      dataType: 'json',
      body: fData,
    })
      .then((res) => {
        targetDetail.likeCount = (targetDetail.likeCount || 0) + 1;
        targetDetail.liked = true;
        setDataSource([...dataSource]);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const onSuccess = (id, { updateInfo, details }, loaded) => {
    const newDataSource = dataSource.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...updateInfo,
          details,
          loaded,
        };
      }
      return item;
    });
    setDataSource(newDataSource);
  };

  return (
    <Layout current="sources" subCurrent="updateInfo">
      <AboutUsBanner
        title="产品更新"
        description="愿Convertlab成为全球企业值得信赖的营销伙伴"
        imgName="about"
      />
      <section id="update-list-section" className="update-list-section">
        <Container>
          <Timeline mode="left">
            {dataSource.map((updateItem) => {
              const isActive = updateItem.id == currentInfo;
              return (
                <Timeline.Item
                  key={updateItem.id}
                  label={updateItem.publishDate}
                  className={isActive ? 'active' : ''}
                >
                  <div className="update-info">
                    <p className="title">{updateItem.title}</p>
                    <p className="common-text briefs">{updateItem.briefs}</p>
                    {isActive ? (
                      <div className="update-detail">
                        <p className="common-text desc">{updateItem.desc}</p>
                        {updateItem.details.map((detail) => {
                          const actions = [
                            <Tooltip key="comment-basic-like" title="点赞">
                              <span onClick={detail.liked ? null : () => like(updateItem, detail)} className={detail.liked ? 'liked' : ''}>
                                {createElement(LikeFilled)}
                                <span className="comment-action">{detail.likeCount}</span>
                              </span>
                            </Tooltip>,
                          ];
                          return (
                            <Comment
                              actions={actions}
                              avatar={
                                <div className="avatar-info">
                                  <Avatar src={detail.img} alt={detail.name} size={80} />
                                  <p>{detail.name}</p>
                                  <p>{detail.jobTitle}</p>
                                </div>
                              }
                              content={
                                <>
                                  <p className="title">{detail.title}</p>
                                  <p className="common-text desc">{detail.desc}</p>
                                </>
                              }
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <NeedMoreBtn
                        updateItem={updateItem}
                        setCurrentInfo={setCurrentInfo}
                        onSuccess={onSuccess}
                      />
                    )}
                  </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </Container>
      </section>
    </Layout>
  );
};

const NeedMoreBtn = ({ updateItem, onSuccess, setCurrentInfo }) => {
  const [loading, setLoading] = useState(false);

  const { id, loaded, hyperLink } = updateItem;

  const loadMore = () => {
    setCurrentInfo(id);
    if (updateItem.hyperLink) {
      window.open(hyperLink, '_blank');
      return;
    }
    if (loaded) return;
    const fData = new FormData();
    fData.append('infoid', id);
    fetch(`/_api/updateInfo`, {
      method: 'POST',
      dataType: 'json',
      body: fData,
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        res.details = res?.details?.map((item) => {
          const detail = { ...item };
          // 判断更新详情里是否有超链接
          if (/\[(>|(&gt;)){2,}点击查看功能详细说明\].+http[s]?.+/.test(detail?.desc)) {
            const href = detail.desc.match(/http[s]:\/\/[\w\.\/]+/);
            const desc = detail.desc.match(/[^\[]+/);
            // 替换超链接
            if (href) {
              detail.desc = (
                <>
                  {desc}
                  < a href={href} target='_blank'>{`>>点击查看功能详细说明`}</a >
                </>
              )
            } else {
              // 去除超链接
              detail.desc = desc;
            }
          }
          return detail;
        })
        onSuccess(id, res, true);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      style={{ padding: 0 }}
      type='link'
      loading={loading}
      onClick={loadMore}>了解更多 &gt;
    </Button>
  );
}

